<style>
  .settings-collapse {
    margin-bottom: 1rem;
  }
  .hard-border {
    border-radius: 0 !important;
  }
</style>
<script>
import {  VclCode } from 'vue-content-loading';
import { OrbitSpinner } from 'epic-spinners';
import Multiselect from "vue-multiselect";

import CopyableText from "@/components/CopyableText";
import {get_acsrf_token} from "@/methods";
import {mapGetters} from "vuex";

export default {
  props: ['banlist_id', 'options'],
  components: {
    // eslint-disable-next-line vue/no-unused-components
    CopyableText,
    VclCode,
    OrbitSpinner,
    Multiselect
  },
  computed: {
    ...mapGetters([
      'getTeams'
    ]),
  },
  methods: {
    handleError: function(error) {
      console.log(`[ERROR] ${error}`);
      this.error = true;
    },
    gryphonChartOptions() {
      return {}
    },
    async removeFollowers() {
      this.removeFollowersInProgress = true;
      await new Promise(resolve => setTimeout(resolve, 1000));
      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/banmanager/${this.banlist_id}/wipe-followers`, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });
        if(response.ok) {
          this.$swal({
            icon: 'success',
            text: this.$t('banmanager.view.settings.dangerzone.remove_followers.success.text'),
            title: this.$t('banmanager.view.settings.dangerzone.remove_followers.success.title')
          });
        } else {
          if(response.status === 403) {
            this.$swal({
              icon: 'error',
              text: this.$t('error.permissions.message'),
              title: this.$t('error.permissions.title')
            });
          } else if(response.status === 429) {
            this.$swal({
              icon: 'warning',
              text: this.$t('error.server.ratelimit.action'),
              title: this.$t('error.server.ratelimit.title')
            });
          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$swal({
          icon: 'error',
          text: this.$t('error.server.generic.message')
        });
      }
      this.removeFollowersInProgress = false;
    },
    async wipeBans() {
      this.wipeInProgress = true;
      await new Promise(resolve => setTimeout(resolve, 1000));
      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/banmanager/${this.banlist_id}/wipe-bans`, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });
        if(response.ok) {
          this.$swal({
            icon: 'success',
            title: this.$t('banmanager.view.settings.dangerzone.wipe.success.title')
          });
        } else {
          if(response.status === 403) {
            this.$swal({
              icon: 'error',
              text: this.$t('error.permissions.message'),
              title: this.$t('error.permissions.title')
            });
          } else if(response.status === 429) {
            this.$swal({
              icon: 'warning',
              text: this.$t('error.server.ratelimit.action'),
              title: this.$t('error.server.ratelimit.title')
            });
          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$swal({
          icon: 'error',
          text: this.$t('error.server.generic.message')
        });
      }
      this.wipeInProgress = false;
    },
    async deleteBanList() {
      let result = await this.$swal({
        icon: 'warning',
        text: this.$t('banmanager.view.settings.dangerzone.delete.confirm.text'),
        title: this.$t('banmanager.view.settings.dangerzone.delete.confirm.title'),
        showCancelButton: true,
        confirmButtonText: this.$t('banmanager.view.settings.dangerzone.delete.button'),
        confirmButtonColor: '#f46a6a',
        cancelButtonColor: '#c3cbe4',
      });
      if(!result.isConfirmed) return;

      this.$emit('actionEnableFullpage', this.$t('banmanager.view.settings.dangerzone.delete.in_progress'), 'danger', true);
      this.$emit('actionToggleFullpage');

      await new Promise(resolve => setTimeout(resolve, 1000));

      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/banmanager/${this.banlist_id}/delete`, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });
        if(response.ok) {
          await this.$router.push({name: 'ban-manager'});
        } else {
          if(response.status === 403) {
            this.$swal({
              icon: 'error',
              text: this.$t('error.permissions.message'),
              title: this.$t('error.permissions.title')
            });
          } else if(response.status === 429) {
            this.$swal({
              icon: 'warning',
              text: this.$t('error.server.ratelimit.action'),
              title: this.$t('error.server.ratelimit.title')
            });
          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$swal({
          icon: 'error',
          text: this.$t('error.server.generic.message')
        });
      }
      this.$emit('actionToggleFullpage');
    },
    async onToggleOption(setting, event) {
      this.transmission[setting] = true;
      setTimeout(() => {
        this.updateSetting(setting, event.value);
      }, 750);
    },
    async updateSetting(setting, value) {
      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          setting: setting,
          value: value
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/banmanager/${this.banlist_id}/setting`, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });
        if(response.ok) {
          // Ok
        } else {
          if(response.status === 429) {
            this.settings[setting] = !value;
            this.$swal({
              icon: 'warning',
              text: this.$t('error.server.ratelimit.message'),
              title: this.$t('error.server.ratelimit.title')
            });
          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        this.settings[setting] = !value;
        console.log(`[ERROR] ${error}`);
        this.$swal({
          icon: 'error',
          text: this.$t('error.server.generic.message')
        });
      }
      this.transmission[setting] = false;
    },
    getSettings: function() {
      fetch(process.env.VUE_APP_ROOT_API + `v1/banmanager/${this.banlist_id}/settings`, {credentials: 'include'})
        .then(response => {
          if(response.ok){
            return response.json();
          } else {
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
          }
        })
        .then(data => {
          this.ready = true;
          this.settings = data.settings;
          Object.keys(this.settings).forEach(k => {
            this.transmission[k] = false;
          });
          this.assignedTeam = data.team;
        })
        .catch(error => {
          this.handleError(error);
        });
    },
    getFollows: function() {
      fetch(process.env.VUE_APP_ROOT_API + `v1/banmanager/${this.banlist_id}/follows`, {credentials: 'include'})
          .then(response => {
            if(response.ok){
              return response.json();
            } else {
              throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
            }
          })
          .then(data => {
            this.follows = data.follows;
          })
          .catch(error => {
            this.handleError(error);
          });
    },
    async followBanlist() {
      if(this.shareCode.length !== 36 || (this.shareCode.match(/-/g) || []).length !== 4) {
        this.$refs.shareCode.$el.classList.add('is-invalid');
        return;
      } else {
        this.$refs.shareCode.$el.classList.remove('is-invalid');
        this.$refs.shareCode.$el.classList.add('is-valid');
        setTimeout(() => {
          this.$refs.shareCode.$el.classList.remove('is-valid');
        }, 750);
      }
      this.followInProgress = true;
      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          banlist_uuid: this.shareCode
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/banmanager/${this.banlist_id}/follows`, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });
        if(response.ok) {
          this.shareCode = null;
          this.getFollows();
          this.$swal({
            icon: 'success',
            text: this.$t('banmanager.view.settings.follows.add.success.message'),
            title: this.$t('banmanager.view.settings.follows.add.success.title')
          });
        } else {
          //if(response.)
          if(response.status === 429) {
            this.$swal({
              icon: 'warning',
              text: this.$t('error.server.ratelimit.message'),
              title: this.$t('error.server.ratelimit.title')
            });
          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$swal({
          icon: 'error',
          text: this.$t('banmanager.view.settings.follows.add.error.title')
        });
      }
      this.followInProgress = false;
    },
    async unfollowBanlist(follow) {
      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          following_banlist_id: follow.id
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/banmanager/${this.banlist_id}/unfollow`, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });
        if(response.ok) {
          this.getFollows();
          this.$swal({
            icon: 'success',
            title: this.$t('banmanager.view.settings.follows.remove.success')
          });
        } else {
          if(response.status === 429) {
            this.$swal({
              icon: 'warning',
              text: this.$t('error.server.ratelimit.message'),
              title: this.$t('error.server.ratelimit.title')
            });
          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$swal({
          icon: 'error',
          text: this.$t('error.server.generic.message')
        });
      }
    },
    async assignToTeam() {
      if(!this.selectedTeam) return;
      this.teamInProgress = true;
      await new Promise(resolve => setTimeout(resolve, 1000));
      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          team_uuid: this.selectedTeam.uuid
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/banmanager/${this.banlist_id}/team`, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });
        if(response.ok) {
          let data = await response.json();
          if(data.team !== null) {
            this.assignedTeam = data.team;
            this.selectedTeam = null;
            this.$swal({
              icon: 'success',
              title: this.$t('banmanager.view.settings.team.add.success')
            });
          } else {
            this.assignedTeam = null;
            this.$swal({
              icon: 'success',
              title: this.$t('banmanager.view.settings.team.remove.success')
            });
          }
        } else {
          if(response.status === 429) {
            this.$swal({
              icon: 'warning',
              text: this.$t('error.server.ratelimit.message'),
              title: this.$t('error.server.ratelimit.title')
            });
          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$swal({
          icon: 'error',
          text: this.$t('error.server.generic.message')
        });
      }
      this.teamInProgress = false;
    },
    async removeFromTeam() {
      this.teamInProgress = true;
      await new Promise(resolve => setTimeout(resolve, 1000));
      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          team_uuid: null
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/banmanager/${this.banlist_id}/team`, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });
        if(response.ok) {
          let data = await response.json();
          if(data.team !== null) {
            this.assignedTeam = data.team;
            this.$swal({
              icon: 'success',
              title: this.$t('banmanager.view.settings.team.add.success')
            });
          } else {
            this.assignedTeam = null;
            this.$swal({
              icon: 'success',
              title: this.$t('banmanager.view.settings.team.remove.success')
            });
          }
        } else {
          if(response.status === 429) {
            this.$swal({
              icon: 'warning',
              text: this.$t('error.server.ratelimit.message'),
              title: this.$t('error.server.ratelimit.title')
            });
          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$swal({
          icon: 'error',
          text: this.$t('error.server.generic.message')
        });
      }
      this.teamInProgress = false
    }
  },
  mounted() {
    this.getSettings();
    this.getFollows();
    this.getTeams().forEach((team) => {
      this.availableTeams.push({
        name: team.name,
        uuid: team.uuid
      })
    })
  },
  data() {
    return {
      ready: false,
      error: false,
      inProgress: false,
      removeFollowersInProgress: false,
      wipeInProgress: false,
      shareCode: null,
      followInProgress: false,
      follows: [],
      settings: {

      },
      transmission: {
        public: null,
        evidence_public: null,
        allow_followers: null,
        keep_inactive: null
      },
      availableTeams: [],
      selectedTeam: null,
      assignedTeam: null,
      teamInProgress: false
    }
  }
};
</script>

<template>
  <div>
    <template v-if="ready">
      <div class="row">
        <div class="col-lg-9">
          <div>
            <div role="tablist">
              <b-card no-body class="shadow-none settings-collapse">
                <a v-b-toggle.accordion-1 class="text-dark" href="javascript: void(0);">
                  <b-card-header header-tag="header" role="tab">
                    <h3 class="m-0 d-inline-flex">
                      {{ $t('banmanager.view.settings.general.title') }}
                    </h3>
                    <div class="d-inline-flex float-right">
                      <div class="btn btn-sm btn-dark">
                        {{ $t('banmanager.view.settings.expand') }}
                      </div>
                    </div>
                  </b-card-header>
                </a>

                <!-- General -->
                <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <b-card-text>
                      <div class="row">
                        <div class="w-75">
                          <div class="ml-4 mr-4">
                            <h4>
                              {{ $t('banmanager.view.settings.general.public.title') }}
                            </h4>
                            <span>
                              {{ $t('banmanager.view.settings.general.public.description') }}
                            </span>
                          </div>
                        </div>
                        <div class="w-25">
                          <div style="text-align: center;">
                            <toggle-button ref="settingsPublic" v-model="settings.public" @change="onToggleOption('public', $event)"
                              :disabled="transmission.public"
                              :sync="true"
                              :color="{checked: '#34c38f', unchecked: '#f46a6a'}"
                              :height="40"
                              :width="80"
                              :labels="{checked: $t('terms.on'), unchecked: $t('terms.off')}"
                              style="font-size: 13px !important;"
                              class="m-0 mt-1 mb-1"
                            />
                            <div class="d-inline ml-3" v-if="$refs.settingsPublic && $refs.settingsPublic.disabled">
                              <i class="far fa-circle-notch fa-spin text-white font-size-16"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr>
                      <div class="row">
                        <div class="w-75">
                          <div class="ml-4 mr-4">
                            <h4>
                              {{ $t('banmanager.view.settings.general.evidence_public.title') }}
                            </h4>
                            <span>
                              {{ $t('banmanager.view.settings.general.evidence_public.description') }}
                            </span>
                          </div>
                        </div>
                        <div class="w-25">
                          <div style="text-align: center;">
                            <toggle-button ref="settingsEvidencePublic" v-model="settings.evidence_public" @change="onToggleOption('evidence_public', $event)"
                               :disabled="transmission.evidence_public"
                               :sync="true"
                               :color="{checked: '#34c38f', unchecked: '#f46a6a'}"
                               :height="40"
                               :width="80"
                               :labels="{checked: $t('terms.on'), unchecked: $t('terms.off')}"
                               style="font-size: 13px !important;"
                               class="m-0 mt-1 mb-1"
                            />
                            <div class="d-inline ml-3" v-if="$refs.settingsEvidencePublic && $refs.settingsEvidencePublic.disabled">
                              <i class="far fa-circle-notch fa-spin text-white font-size-16"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr>
                      <div class="row">
                        <div class="w-75">
                          <div class="ml-4 mr-4">
                            <h4>
                              {{ $t('banmanager.view.settings.general.allow_followers.title') }}
                            </h4>
                            <span>
                              {{ $t('banmanager.view.settings.general.allow_followers.description') }}
                            </span>
                          </div>
                        </div>
                        <div class="w-25">
                          <div style="text-align: center;">
                            <toggle-button ref="settingsAllowFollowers" v-model="settings.allow_followers" @change="onToggleOption('allow_followers', $event)"
                                           :disabled="transmission.allow_followers"
                                           :sync="true"
                                           :color="{checked: '#34c38f', unchecked: '#f46a6a'}"
                                           :height="40"
                                           :width="80"
                                           :labels="{checked: $t('terms.on'), unchecked: $t('terms.off')}"
                                           style="font-size: 13px !important;"
                                           class="m-0 mt-1 mb-1"
                            />
                            <div class="d-inline ml-3" v-if="$refs.settingsAllowFollowers && $refs.settingsAllowFollowers.disabled">
                              <i class="far fa-circle-notch fa-spin text-white font-size-16"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>

              <!-- Team -->
              <b-card no-body class="settings-collapse hard-border">
                <a v-b-toggle.team class="text-dark" href="javascript: void(0);">
                  <b-card-header header-tag="header" role="tab">
                    <h3 class="m-0 d-inline-flex">
                      {{ $t('banmanager.view.settings.team.title') }}
                    </h3>
                    <div class="d-inline-flex float-right">
                      <div class="btn btn-sm btn-dark">
                        {{ $t('banmanager.view.settings.expand') }}
                      </div>
                    </div>
                  </b-card-header>
                </a>
                <b-collapse id="team" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <b-card-text>
                      <div class="row">
                        <div class="col justify-content-center">
                          <h5 class="text-center text-muted">
                            {{ $t('banmanager.view.settings.team.description') }}
                          </h5>
                        </div>
                      </div>
                      <template v-if="assignedTeam === null">
                        <div class="row justify-content-center">
                          <div class="col-lg-6 col-sm-12">
                            <multiselect
                                v-model="selectedTeam"
                                label="title"
                                track-by="title"
                                :options="availableTeams"
                                :show-labels="false"
                                :allow-empty="false"
                            >
                              <template slot="singleLabel" slot-scope="props">
                                <div>
                                  <span class="option__title align-middle h4">
                                    {{ props.option.name }}
                                  </span>
                                  <div class="option__desc d-inline align-middle">
                                    {{props.option.uuid}}
                                  </div>
                                </div>
                              </template>
                              <template slot="option" slot-scope="props">
                                <div>
                                  <div class="option__desc d-inline ml-2">
                                    <span class="option__title align-middle h4">
                                      {{ props.option.name }}
                                    </span>
                                    <span class="option__small align-middle ml-2 h6">
                                      {{ props.option.uuid }}
                                    </span>
                                  </div>
                                </div>
                              </template>
                            </multiselect>
                          </div>
                        </div>
                        <div class="row mt-2 justify-content-center">
                          <div class="col-lg-3 col-sm-12">
                            <button class="btn btn-primary btn-block" v-on:click="assignToTeam()" :class="{'disabled': teamInProgress}">
                              <orbit-spinner
                                  v-if="teamInProgress"
                                  :animation-duration="1200"
                                  :size="16"
                                  class="align-middle d-inline-block"
                              />
                              {{ $t('banmanager.view.settings.team.add.button') }}
                            </button>
                          </div>
                        </div>
                      </template>
                      <template v-else>
                        <div class="row justify-content-center">
                          <div class="col-lg-6 col-sm-12 text-center">
                            <span class="option__title align-middle h4">
                              {{ assignedTeam.name }}
                            </span>
                            <div class="option__desc">
                              {{assignedTeam.uuid}}
                            </div>
                          </div>
                        </div>
                        <div class="row mt-2 justify-content-center">
                          <div class="col-lg-3 col-sm-12">
                            <button class="btn btn-warning text-black btn-block" v-on:click="removeFromTeam()" :class="{'disabled': teamInProgress}">
                              <orbit-spinner
                                  v-if="teamInProgress"
                                  :animation-duration="1200"
                                  :size="16"
                                  class="align-middle d-inline-block"
                              />
                              {{ $t('banmanager.view.settings.team.remove.button') }}
                            </button>
                          </div>
                        </div>
                      </template>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>

              <!-- Behaviour -->
              <b-card no-body class="settings-collapse hard-border">
                <a v-b-toggle.accordion-2 class="text-dark" href="javascript: void(0);">
                  <b-card-header header-tag="header" role="tab">
                    <h3 class="m-0 d-inline-flex">
                      {{ $t('banmanager.view.settings.behaviour.title') }}
                    </h3>
                    <div class="d-inline-flex float-right">
                      <div class="btn btn-sm btn-dark">
                        {{ $t('banmanager.view.settings.expand') }}
                      </div>
                    </div>
                  </b-card-header>
                </a>
                <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <b-card-text>
                      <!-- Keep inactive bans -->
                      <div class="row">
                        <div class="w-75">
                          <div class="ml-4 mr-4">
                            <h4>
                              {{ $t('banmanager.view.settings.behaviour.keep.title') }}
                            </h4>
                            <span>
                              {{ $t('banmanager.view.settings.behaviour.keep.description') }}
                            </span>
                          </div>
                        </div>
                        <div class="w-25">
                          <div style="text-align: center;">
                            <toggle-button ref="settingsKeepInactive" v-model="settings.keep_inactive" @change="onToggleOption('keep_inactive', $event)"
                                           :disabled="transmission.keep_inactive"
                                           :sync="true"
                                           :color="{checked: '#34c38f', unchecked: '#f46a6a'}"
                                           :height="40"
                                           :width="80"
                                           :labels="{checked: $t('terms.on'), unchecked: $t('terms.off')}"
                                           style="font-size: 13px !important;"
                                           class="m-0 mt-1 mb-1"
                            />
                            <div class="d-inline ml-3" v-if="$refs.settingsKeepInactive && $refs.settingsKeepInactive.disabled">
                              <i class="far fa-circle-notch fa-spin text-white font-size-16"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>

              <!-- Follows -->
              <b-card no-body class="settings-collapse hard-border">
                <a v-b-toggle.follow class="text-dark" href="javascript: void(0);">
                  <b-card-header header-tag="header" role="tab">
                    <h3 class="m-0 d-inline-flex">
                      {{ $t('banmanager.view.settings.follows.title') }}
                    </h3>
                    <div class="d-inline-flex float-right">
                      <div class="btn btn-sm btn-dark">
                        {{ $t('banmanager.view.settings.expand') }}
                      </div>
                    </div>
                  </b-card-header>
                </a>
                <b-collapse id="follow" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <b-card-text>
                      <div class="row">
                        <div class="col justify-content-center">
                          <h5 class="text-center text-muted">
                            {{ $t('banmanager.view.settings.follows.description') }}
                          </h5>
                        </div>
                      </div>
                      <!-- Share code -->
                      <div class="row justify-content-center">
                        <div class="col-lg-6 col-sm-12">
                          <b-input-group size="lg">
                            <b-form-input ref="shareCode" v-model="shareCode" :placeholder="$t('banmanager.view.settings.follows.add.label')" />
                            <b-input-group-append>
                              <b-button variant="primary" v-on:click="followBanlist()" :class="{'disabled': followInProgress}">
                                <orbit-spinner
                                    v-if="followInProgress"
                                    :animation-duration="1200"
                                    :size="16"
                                    class="align-middle d-inline-block"
                                />

                                {{ $t('banmanager.view.settings.follows.add.button') }}
                              </b-button>
                            </b-input-group-append>
                          </b-input-group>
                        </div>
                      </div>
                      <!-- List -->

                      <div class="row mt-2 justify-content-center" v-if="follows.length">
                        <div class="col-lg-5 col-sm-12">
                          <h5>{{ $t('banmanager.view.settings.follows.list') }}</h5>
                          <div class="table-responsive">
                            <table class="table table-nowrap table-centered table-hover mb-0 align-middle">
                              <tbody>
                              <tr v-for="follow in follows" :key="follow.id">
                                <td>
                                  <h5 class="font-size-14 mb-1">
                                    {{ follow.identifier }}
                                  </h5>
                                  <small>
                                    ID:
                                    <CopyableText :text="follow.id">{{follow.id}}</CopyableText>
                                  </small>
                                </td>
                                <td>
                                  <div class="text-center">
                                    <button class="btn btn-warning text-black" v-on:click="unfollowBanlist(follow)">
                                      {{ $t('banmanager.view.settings.follows.remove.button') }}
                                    </button>
                                  </div>
                                </td>
                              </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div class="row" v-else>
                        <div class="col text-center">
                          <span class="text-muted">
                            {{ $t('banmanager.view.settings.follows.none') }}
                          </span>
                        </div>
                      </div>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>

              <!-- Dangerzone -->
              <b-card no-body class="settings-collapse border border-danger hard-border">
                <a v-b-toggle.accordion-3 class="text-dark" href="javascript: void(0);">
                  <b-card-header header-tag="header" role="tab">
                    <h3 class="m-0 d-inline-flex text-danger">
                      {{ $t('banmanager.view.settings.dangerzone.title') }}
                    </h3>
                    <div class="d-inline-flex float-right">
                      <div class="btn btn-sm btn-dark">
                        {{ $t('banmanager.view.settings.expand') }}
                      </div>
                    </div>
                  </b-card-header>
                </a>
                <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <b-card-text>
                      <div class="row">
                        <div class="w-75">
                          <div class="ml-4 mr-4">
                            <h4 class="text-danger">
                              {{ $t('banmanager.view.settings.dangerzone.wipe.title') }}
                            </h4>
                            <span>
                              {{ $t('banmanager.view.settings.dangerzone.wipe.description') }}
                            </span>
                          </div>
                        </div>
                        <div class="w-25">
                          <div style="text-align: center;">
                            <button class="btn btn-block btn-danger" v-on:click="wipeBans()" :class="{'disabled': wipeInProgress}">
                              <orbit-spinner
                                  v-if="wipeInProgress"
                                  :animation-duration="1200"
                                  :size="16"
                                  class="align-middle d-inline-block"
                              />
                              {{ $t('banmanager.view.settings.dangerzone.wipe.button') }}
                            </button>
                          </div>
                        </div>
                      </div>
                      <hr>
                      <div class="row">
                        <div class="w-75">
                          <div class="ml-4 mr-4">
                            <h4 class="text-danger">
                              {{ $t('banmanager.view.settings.dangerzone.remove_followers.title') }}
                            </h4>
                            <span>
                              {{ $t('banmanager.view.settings.dangerzone.remove_followers.description') }}
                            </span>
                          </div>
                        </div>
                        <div class="w-25">
                          <div style="text-align: center;">
                            <button class="btn btn-block btn-danger" v-on:click="removeFollowers()" :class="{'disabled': removeFollowersInProgress}">
                              <orbit-spinner
                                  v-if="removeFollowersInProgress"
                                  :animation-duration="1200"
                                  :size="16"
                                  class="align-middle d-inline-block"
                              />
                              {{ $t('banmanager.view.settings.dangerzone.remove_followers.button') }}
                            </button>
                          </div>
                        </div>
                      </div>
                      <hr>
                      <div class="row" v-if="options.privileged">
                        <div class="w-75">
                          <div class="ml-4 mr-4">
                            <h4 class="text-danger">
                              {{ $t('banmanager.view.settings.dangerzone.delete.title') }}
                            </h4>
                            <span>
                              {{ $t('banmanager.view.settings.dangerzone.delete.description') }}
                            </span>
                          </div>
                        </div>
                        <div class="w-25">
                          <div style="text-align: center;">
                            <button class="btn btn-block btn-danger" v-on:click="deleteBanList()">
                              {{ $t('banmanager.view.settings.dangerzone.delete.button') }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="error">
      <div class="row">
        <div class="col-lg-12 col-sm-12">
          <div class="card border border-danger">
            <div class="card-header bg-transparent border-danger">
              <h5 class="my-0 text-danger">
                <i class="far fa-exclamation-circle mr-3"></i>
                {{$t('error.server.generic.title')}}
              </h5>
            </div>
            <div class="card-body pt-0">
              <h5 class="card-title mt-0"> {{$t('error.server.generic.component')}}</h5>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="row">
        <div class="col-lg-11">
          <!-- Content row one -->
          <div class="row">
            <div class="col">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
